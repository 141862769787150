import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useRoute from '@vl/hooks/useGbRouteDe';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { decode } from '@vl/mod-utils/jwt';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchTemplate: false }))),
            getMemberId: hook(() => {
              const route = useRoute();
              const paramsData = _.get(route, 'params.id', '');
              return paramsData;
            }),

            form: hook((ctx) => {
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  emails: [{ id: 1, content: '' }],
                },

                onSubmit: async (values, actions) => {
                  try {
                    console.log(values);
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                canSubmit: form.isValid && form.dirty,

                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        id: 2,
                        content: '',
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
              });

              return form;
            }),

            '@item': hook((ctx) => {
              const member_id = ctx.get('getMemberId');
              const account_id = ctx.apply('accountModel.getAccountId');

              const data = usePromiseSource(
                async () => {
                  try {
                    if (!member_id) return null;
                    const client = await getClient();
                    const member_data = await client.request(
                      gql`
                        query GetMemberInfo($account_id: uuid!, $user_id: String!) {
                          user_info: b2b_member_by_pk(account_id: $account_id, user_id: $user_id) {
                            id
                            account_id
                            member_profile {
                              phone
                              created_at
                              first_name
                              last_name
                              avatar_url
                            }
                            is_active
                          }
                          course_ongoing: b2b_course_aggregate(
                            where: {
                              account_id: { _eq: $account_id }
                              course_enrolls: { user_id: { _eq: $user_id } }
                              status: { _eq: "ongoing" }
                            }
                          ) {
                            aggregate {
                              count
                            }
                          }
                          course_upcoming: b2b_course_aggregate(
                            where: {
                              account_id: { _eq: $account_id }
                              course_enrolls: { user_id: { _eq: $user_id } }
                              status: { _eq: "draft" }
                            }
                          ) {
                            aggregate {
                              count
                            }
                          }
                          course_finished: b2b_course_aggregate(
                            where: {
                              account_id: { _eq: $account_id }
                              course_enrolls: { user_id: { _eq: $user_id } }
                              status: { _eq: "completed" }
                            }
                          ) {
                            aggregate {
                              count
                            }
                          }
                        }
                      `,
                      { account_id: account_id, user_id: member_id }
                    );
                    return member_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [member_id]
              );

              console.log(data);
              return data;
            }),

            statisticModel: hook((ctx) => {
              const statistics = [
                {
                  title: ctx.apply('i18n.t', 'ZoomToolMember.Program.ongoing'),
                  data: ctx.apply('i18n.t', 'ZoomToolMember.Program.program', { count: 0 }),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolMember.Program.upcoming'),
                  data: ctx.apply('i18n.t', 'ZoomToolMember.Program.program', { count: 0 }),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolMember.Program.finished'),
                  data: ctx.apply('i18n.t', 'ZoomToolMember.Program.program', { count: 0 }),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolMember.Course.ongoing'),
                  data: ctx.apply('i18n.t', 'ZoomToolMember.Course.course', {
                    count: ctx.get('@item.course_ongoing.aggregate.count') || 0,
                  }),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolMember.Course.upcoming'),
                  data: ctx.apply('i18n.t', 'ZoomToolMember.Course.course', {
                    count: ctx.get('@item.course_upcoming.aggregate.count') || 0,
                  }),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolMember.Course.finished'),
                  data: ctx.apply('i18n.t', 'ZoomToolMember.Course.course', {
                    count: ctx.get('@item.course_finished.aggregate.count') || 0,
                  }),
                },
              ];
              return statistics;
            }),

            modalModel: hook((ctx) => {
              const [isModalVisible, setIsModalVisible] = React.useState(false);
              return {
                isModalVisible,
                title: ctx.apply('i18n.t', 'ZoomToolStudent.send_mail_title'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return _.isEmpty(ctx.get('@item'));
          }),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !_.isEmpty(ctx.get('@item'))),
        },
      ],
    ],
  },
});

export default bindData;
