import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Avatar from '@uz/unitz-components-web/Avatar';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';

const View8 = () => {
  return (
    <DIV className="component">
      <Row className="py-10" justify="center" align="middle">
        <Col span={16}>
          <Row align="middle" gutter={[0, 0]}>
            <Col span={10}>
              <Avatar size={232} src={MemberFormatter.avatarUrl(ctx)(ctx.get('itemData'))} />
            </Col>
            <Col span={14}>
              <div className="text-sub font-semibold" style={{ fontSize: 32 }}>
                {MemberFormatter.fullName(ctx)(ctx.get('itemData'))}
              </div>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.type')}:{' '}
                {ctx.apply('i18n.t', 'ZoomToolMember.Form.undefined')}
              </div>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolMember.Table.phone_number')}:{' '}
                {MemberFormatter.profilePhoneNumber(ctx)(ctx.get('itemData'))}
              </div>
              <div className="my-2 text-sub text-xl font-semibold">
                {ctx.apply('i18n.t', 'ZoomToolMember.start_date')}:{' '}
                {MemberFormatter.startDate(ctx)(ctx.get('itemData'))}
              </div>
              <div className="my-2 text-sub text-xl font-semibold flex items-center">
                {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.status')}:
                {!!ctx.get('itemData.is_active') ? (
                  <div className="ml-3 text-xs py-1 px-2 bg-brandb500 text-white500 rounded-lg">
                    {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.active')}
                  </div>
                ) : (
                  <div className="ml-3 text-xs py-1 px-2 bg-background2 text-sub rounded-lg">
                    {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.disabled')}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </DIV>
  );
};

export default displayName(View8);
